* { 
    box-sizing: border-box; 
  }
  
  body { 
    margin: 0; 
    padding-bottom: 4rem; 
  }
  
  .ticker-wrap {
    position: fixed;
    bottom: 0;
    width: 100%;
    overflow: hidden;
    height: 4rem;
    background-color: rgba(0, 0, 0, 0.9);
    color: white;
    box-sizing: content-box;
    
  }
  
  .ticker {
    display: flex;
    align-items: center;
    animation: ticker 30s linear infinite;
  }
  
  .ticker__item {
    display: inline-block;
    padding: 0 2rem;
    height: 4rem;
    line-height: 4rem;
    white-space: nowrap;
  }
  
  @keyframes ticker {
    0% {
      transform: translate3d(100%, 0, 0);
    }
    100% {
      transform: translate3d(-100%, 0, 0);
    }
  }
  
  .ticker-wrap:hover .ticker {
    animation-play-state: paused;
  }