.tabs button {
    padding: 10px;
    margin: 0 5px;
    background-color: #eee;
    border: none;
    cursor: pointer;
  }
  
  .tabs button.active {
    background-color: #007bff;
    color: white;
  }
  
  .session {
    margin: 10px 0;
    display: flex;
    align-items: center;
  }
  
  .session label {
    margin-left: 10px;
  }
  .workshops-tabs {
    margin-bottom: 20px;
    display: flex;
    justify-content: center;
  }
  
  .workshops-tabs button {
    padding: 10px 20px;
    margin: 0 5px;
    border: none;
    background-color: #f0f0f0;
    cursor: pointer;
  }
  
  .workshops-tabs button.active-tab {
    background-color: #007bff;
    color: white;
  }
  
  .workshop {
    margin: 10px 0;
  }
  
  /* Further styling as needed */
  /* Tabs container */
.tabs {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
  }
  
  /* Tab buttons */
  .tabs button {
    padding: 10px 20px;
    border: none;
    background-color: #f0f0f0;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .tabs button.active {
    background-color: #007bff;
    color: white;
  }
  
  /* Workshop and session cards */
  .workshop, .session {
    background-color: #ffffff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    padding: 15px;
    margin: 10px 0;
    display: flex;
    align-items: center;
  }
  
  /* Checkbox and label styling */
  .workshop input[type="checkbox"], .session input[type="checkbox"] {
    margin-right: 15px;
  }
  
  .workshop label, .session label {
    margin: 0;
    flex-grow: 1;
  }
  
  /* Workshop and session information */
  .workshop-info, .session-info {
    display: flex;
    flex-direction: column;
  }
  
  .workshop-title, .session-title {
    font-size: 18px;
    font-weight: bold;
    color: #333;
  }
  
  .workshop-time, .session-time,
  .workshop-speaker, .session-speaker {
    font-size: 14px;
    color: #666;
  }
  
  /* Adjustments for the submit button when disabled */
  .next:disabled {
    background-color: #A9A9A9;
    cursor: not-allowed;
  }
  .tabs button:hover {
    background-color: #0056b3;
    color: white;
  }
  
  .workshop:hover, .session:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.12);
  }
  .tabs button:focus, .tabs button:active {
    outline: none;
    box-shadow: 0 0 0 2px #0056b3;
  }
  
  .workshop input[type="checkbox"]:focus, .session input[type="checkbox"]:focus {
    outline: none;
    box-shadow: 0 0 0 2px #0056b3;
  }
/* General Styles for the Register Form */
.auth-register {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
  }
  
  .form-part {
    background: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 100%; /* Adjust based on your design */
    max-width: 500px; /* Adjust based on your design */
  }
  
  /* Modal Styles */
  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .modal-content {
    background: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    position: relative;
    max-height: 80vh;
    overflow-y: auto; /* Scroll for long content */
  }
  
  .modal-close {
    position: absolute;
    top: 10px;
    right: 10px;
    border: none;
    background: #ccc;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    cursor: pointer;
  }
  
  /* Tabs Styles */
  .tabs {
    display: flex;
    margin-bottom: 20px;
    justify-content: center;
  }
  
  .tabs button {
    padding: 10px;
    border: none;
    background-color: #efefef;
    cursor: pointer;
    transition: background-color 0.2s;
    margin: 0 5px;
  }
  
  .tabs button.active {
    background-color: #007bff;
    color: white;
  }
  
  /* Workshop Card Styles */
  .workshop-card, .session {
    border: 1px solid #eaeaea;
    padding: 15px;
    margin-bottom: 10px;
    border-radius: 5px;
  }
  
  .workshop-info {
    display: flex;
    flex-direction: column;
  }
  
  .workshop-title, .workshop-time, .workshop-speaker {
    margin: 5px 0;
  }
          

  /* Container */
.container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
  }
  
  /* Tabs for switching between workshops and main conference */
  .tabs {
    display: flex;
    justify-content: center;
    gap: 20px;
    margin-bottom: 20px;
  }
  
  .tabs button {
    padding: 10px 20px;
    cursor: pointer;
    border: none;
    background-color: #f0f0f0;
    transition: background-color 0.3s;
  }
  
  .tabs button.active-tab {
    background-color: #007bff;
    color: white;
  }
  
  /* Sub-tabs for morning and afternoon workshops */
  .workshop-subtabs {
    display: flex;
    justify-content: center;
    gap: 20px;
    margin-bottom: 20px;
  }
  
  .workshop-subtabs button {
    padding: 8px 16px;
    cursor: pointer;
    border: none;
    background-color: #e7e7e7;
    transition: background-color 0.3s;
  }
  
  .workshop-subtabs button.active-subtab {
    background-color: #0056b3;
    color: white;
  }
  
  /* Styling for each workshop item */
  .workshop-item {
    padding: 10px;
    border: 1px solid #ddd;
    margin-bottom: 10px;
    border-radius: 5px;
  }
  
  .workshop-item label {
    cursor: pointer;
  }
  
  /* Form Part Styling */
  .form-part {
    background: white;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 4px 6px rgba(0,0,0,0.1);
    margin-top: 20px;
  }
  
  .div-input {
    margin-bottom: 20px;
  }
  
  .div-input label {
    display: block;
    margin-bottom: 5px;
  }
  
  .div-input input {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  /* Button Styling */
  button {
    width: 100%;
    padding: 10px;
    border: none;
    border-radius: 5px;
    color: white;
    background-color: #007bff;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  button:disabled {
    background-color: #ccc;
  }
  
  /* Image Part Styling */
  .img-part img {
    max-width: 100%;
    height: auto;
    margin-bottom: 20px;
  }
  
  /* Modal Overlay */
  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .modal-content {
    background: white;
    padding: 20px;
    border-radius: 5px;
    position: relative;
  }
  
  .modal-close {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
  }
  