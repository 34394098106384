.workshops-tabs {
    margin-bottom: 20px;
    display: flex;
    justify-content: center;
  }
  
  .workshops-tabs button {
    padding: 10px 20px;
    margin: 0 5px;
    border: none;
    background-color: #f0f0f0;
    cursor: pointer;
  }
  
  .workshops-tabs button.active-tab {
    background-color: #007bff;
    color: white;
  }
  
  .workshop {
    margin: 10px 0;
  }
  
  /* Further styling as needed */
  