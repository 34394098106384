thead.sticky-top {
    position: sticky;
    top: 0;
    background-color: white; /* Add background color if needed */
    z-index: 1; /* Ensure the header stays above other elements */
  }
  .modal-90w {
    width: 90vw !important;
}
.modal-backdrop.show {
    opacity:'100%'
}
  