@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&family=Outfit:wght@400;500;600;700&family=Roboto:wght@700&display=swap');
body{
    /* background-color: #FAFAFA; */
    /* margin-right:122px;
    margin-left: 122px; */
    padding: 0px;
    overflow-x: hidden;
    font-family:  'Inter', sans-serif;
    font-weight: 400;
    box-sizing: border-box;
}
a{
    text-decoration: none;
}
a.disabled{
    cursor: not-allowed;
}
ul{
    padding: 0px;
    margin: 0px;
    text-decoration: none;
}
li{
    list-style: none;
}
p{
    margin: 0px;
}
button,input{
    border: none;
    outline: none;
}
.home-back{
    background: #F5FEFD;
    width: 100%;
    height: 100%;
}

.welcome{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.welcome div {
    width: 45%;
}
.welcome div img, .welcome div p{
    width: 100%;
    height: 100%;
}
.welcome .sentence p{
  font-size: 16px;
  color: #888888;
  text-align: justify;
}
.welcome-img{
    margin-top: 100px;
    width: 100%;
    height: 450px;
    overflow: hidden;
    position: relative;
    /* background: url('./welcome.svg') no-repeat; */
}
.overlay-image{
    position: absolute;
    top: 0px;
    left: 0px;
    bottom: 0px;
    width: 100%;
    height: 100%;
    background: #fff;
    z-index: 999;
    opacity: 50%;
    box-shadow: 
    inset 0px 16px 13px -16px rgba(0, 0, 0, .51),
    inset 0px -18px 13px -16px rgba(0, 0, 0, .51);
}
.welcome-img .img-group{
/* position: absolute; */
margin-bottom: 7px;
animation-duration: 5s;
animation-iteration-count:1;
animation-timing-function: linear;
display: flex;
}
.img-group img{
    margin-right: 7px;
}
.img-1{
    position: absolute;
    /* left: -160px; */
    left: -310px;
    animation-name: firstThird;
}
.img-2{
    position: absolute;
    top: 146px;
    /* left: -220px; */
    left: -110px;
    animation-name: secondLast;
}
.img-3{
    position: absolute;
    top: 292px;
    /* left: -160px; */
    left: -310px;
    animation-name: firstThird;
}
.img-4{
    position: absolute;
    top: 438px;
    /* left: -220px; */
    left: -110px;
    animation-name: secondLast;
}
@keyframes firstThird {
    0%{
        left: -160px;
    }
    100%{
        left: -310px;
    }
}
@keyframes secondLast {
    0%{
        left: -220px;
    }
    100%{
        left: -110px;
    }
}
.welcome,.confrence{
    display: flex;
    justify-content: space-between;
}
.confrence{
    margin-top: 100px;
}
.confrence .img{
    width: 35%;
    display: flex;
    flex-direction: column;
    height: 100%;
}
.confrence .img h2{
    font-size: 48px;
    font-weight: 700;
    color: #134F6C;
    margin-bottom: 23px;
}
.confrence .img img{
    width: 100%;
    height: 100%;
}
.confrence .parag{
    width: 60%;
    padding-left: 16px;
    border-left: 2px solid #C1D34C;
    /* height: 100%; */
}
.confrence .parag p:first-of-type{
    font-size: 15px;
    font-weight: 500;
    color: #888888;
    line-height: 34px;
    text-align: justify;
}
.confrence .parag p:last-of-type{
    font-size: 15px;
    font-weight: 500;
    color: #6C635F;
    margin-top: 8px;
}
/* .welcome-img img{
    width: 100%;
    height: 350px;
} */
.objs{
    margin-top: 100px;
}
.objs h2{
    color: #134F6C;
    font-weight: 700;
    font-size: 48px;
    margin-bottom: 32px;
}
.objs ul,.objs ol {
    border-left: 2px solid #C1D34C;
    padding-left: 45px;
}
.objs ul li{
    list-style-type: disc;
    font-size: 16px;
    color: #565656;
    margin-bottom: 9px;
    text-align: justify;
}
.objs ol li{
    list-style-type: decimal;
    font-size: 16px;
    color: #565656;
    margin-bottom: 9px;
    text-align: justify;
}
.line-hr{
    margin-top: 50px;
    margin-bottom: 50px;
}
.more-info h2{
    font-size: 32px;
    font-weight: 700;
    color: #134F6C;
    margin-bottom: 24px;
    text-transform: capitalize;
}
.more-info p{
    font-size: 15px;
    color: #565656;
    padding-left: 16px;
    border-left: 2px solid #C1D34C;
    text-align: justify;
}
.confrences-imgs{
    text-align: center;
}
.confrences-imgs .conf-title{
    font-size: 48px;
    font-weight: 700;
    color: #134F6C;
    margin-bottom: 50px;
}
.confrences-imgs .sections-conf{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.sections-conf .confrence-sec{
    width: 30%;
}
.sections-conf .confrence-sec .img{
    margin-bottom: 78px;
}
.sections-conf .confrence-sec .img img{
  width: 100%;
}
.sections-conf .confrence-sec h2{
    font-size: 32px;
    font-weight: 700;
}
.benefit{
    margin-top: 137px;
    margin-bottom: 99px;
    display: flex;
    justify-content: space-between;
}
.benefit .img{
    width: 50%;
}
.benefit .img img{
    width: 100%;
}
.benefit .parag{
    width: 40%;
}
.benefit .parag h2{
    font-size: 48px;
    font-weight: 700;
    color: #134F6C;
    margin-bottom: 40px;
    text-transform: capitalize;
}
.benefit .parag p{
    font-size: 16px;
    color: #565656;
    padding-left: 16px;
    border-left: 2px solid #C1D34C;
    text-align: justify;
}
.home{
    background-color: #F5FEFD;
    margin: 0px;
    position: relative;
}
.new-application{
    position: fixed;
    width: 60px;
    height: 60px;
    background-color: #C1D34C;
    color: white;
    border-radius: 100%;
    text-align: center;
    z-index: 999;
    top: 80vh;
    display: flex;
    justify-content: center;
    align-items: center;
    right: 50px;
    cursor: pointer;
    /* transition: all .5s ease-in-out; */
    animation-name: application;
    animation-duration: 2s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}
@keyframes application {
    50%{
        top: 90vh;
    }
}
.new-application i{
    font-size: 20px;
}
.home-header{
    display: flex;
padding-top: 63px;
margin-bottom: 63px;
justify-content: space-between;
position: relative;
}
.home-header .big-nav{
    display: flex;  
    justify-content: space-between;
}
.home-header .big-nav li a{
    font-size: 17px;
    font-weight: 500;
    color: #00838E;
    opacity: 50%;
    text-transform: uppercase;
    letter-spacing: .03px;
    cursor: pointer;
}
.home-header .big-nav li a.active{
    opacity: 100%;
}
.home-header .big-nav li:not(:last-of-type){
    margin-right:28px;
}
.home-header .register{
    display: inline;
    font-size: 14px;
    font-weight: 500;
    align-items: center;
}
.home-header .register p{
    color: #141414;
    margin-right: 16px;

}
.menu{
    display: none;
    /* margin: 40px; */
    background:linear-gradient(#C1D34C,#1081A3);
    height: 38px;
    width: 40px;
    border-radius: 4px;
    padding: 10px;
    cursor: pointer;
}
.menu span{
    display: block;
    background-color: #FFFFFF;
    width: 19px;
    height: 1px;
    margin-bottom: 7px;
}
.mobile-menu{
    position: fixed;
    display: block;
    right: -250%;
    /* right: 0; */
    top: 0;
    z-index: 9999;
    width: 400px;
    background-color: #fff;
    height: 100vh;
    transition: right 1s ease-in;
    padding: 25px;
}
.mobile-menu i{
    cursor: pointer;
}
.width-menu{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.logo-img{
width: 70%;
margin-bottom: 30px;
margin-top: 20px;
}
.logo-img img{
    width: 100%;
}
.mob-nav{
    margin-bottom: 20px;
    text-align: center;
}
 .mob-nav li {
    margin-bottom: 20px;
 }
.home-header .register button,.logout-btn{
    padding: 10px 24px;
    color: #fff;
    background-color: #C1D34C;
    border-radius: 8px;
}
.logout-btn{
    width: 120px;
    padding: 10px 24px;
    color: #fff;
    background-color: #C1D34C;
    border-radius: 8px;
}
.login-btn{
    width: 120px;
    padding: 10px 24px;
    color: #fff;
    background-color: #1081a3;
    border-radius: 8px;
}
/*banner*/
.banner{
    margin-top: 15.2px;
}
.banner .p1{
    color: #1081A3;
    font-size: 18px;
    letter-spacing: .6px;
    /* margin-bottom: 24px; */
}
.banner .p2{
    font-size: 48px;
    color: #C1D34C;
    font-weight: 700;
    /* margin-bottom: 40px; */
}
.banner .p3-date{
    font-size: 76px;
    font-weight: 700;
    color: #FAFAFA;
    -webkit-text-stroke-width: 3px;
    -webkit-text-stroke-color: #00838E;
    text-transform: uppercase;
    margin-bottom: 40px;
}
.banner .p4-desc{
    color: #888888;
    line-height: 25px;
    font-size: 11px;
    font-weight: 600;
    text-align: justify;
}
.img{
width: 100%;
height: 100%;
display: flex;
align-items: center;
justify-content: center;
}
.img img{
    width: 100%;
}
.banner .last-p{
color: #4A4848;
font-size: 14px;
font-weight: 700;
margin-top: 15px;
}
.banner .last-p span{
    opacity: 80%;
    font-size: 13px;
}

/* SOON SECTION */
.soon{
    margin-top: 82px;
    margin-bottom: 140px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
}
.soon .div{
    width: 50%;
}
/* .soon .div:first-of-type{
    width: 35%;
}
.soon .div:last-of-type{
    width: 55%;
} */
.soon .back-sqr{
    border-radius: 13px;
    border: 3.4px solid #C1D34C;
     width: 400px; 
     height: 112px;
    position: relative;
}
.soon .front-sqr{
    position: absolute;
background-color: #C1D34C;
display: flex;
align-items: center;
padding: 8px 22px;
color: white;
text-transform: uppercase;
font-size: 16px;
font-weight: 500;
letter-spacing: -.8px;
width: 78%;
border-radius: 13px;
/* top: -20px;
left: 45px; */
justify-content: space-between;
font-family: 'Outfit', sans-serif;
left: 0; 
right: 0; 
top: -20px;
margin-left: auto; 
margin-right: auto; 
}
.front-sqr .image{
    width: 21px;
    height: 21px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 7px;
}
.front-sqr .image img{
    width: 100%;
}
.soon .back-sqr .timer{
    padding: 32px 38px 21px;
    display: flex;
    justify-content: space-between;
    text-align: center;
    align-items: flex-end;
    letter-spacing: -.8px;
}
.timer .sec .title{
    color: #8D8D8D;
    font-size: 11px;
    font-weight: 600;
    text-transform: uppercase;
}
.timer .sec .value, .timer .divider{
    color: #00838E;
    font-size: 36px;
    font-weight: 500;
    font-family: 'Outfit', sans-serif;
}

.soon .coming-img{
    width: 100%;
    display: flex;
    justify-content: flex-end;
}
/* responsive */
.soon .coming-img img{
    width: 100%;
} 

/*Themes setion*/
.themes{
    margin-bottom: 111px;
}
.themes h2{
    font-family: 'Roboto', sans-serif;
    color: #134F6C;
    font-size: 36px;
    margin-bottom: 60px;
}
.themes .theme{
    border-left: 2px solid #C1D34C;
    padding-left: 17px;
    height: 100%;
}
.theme p{
    color: #322C27;
    font-size: 18px;
    font-family:'Inter', sans-serif;
    font-weight: 400;
    margin-bottom: 31px;
}
.themes .theme-img{
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    height: 100%;
}
.theme-img img{
    width: 100%;
}
/*structure section*/

.structure{
    border:1px solid #C1D34C;
    border-radius: 8px;
    text-align: center;
    width: 920px;
    height: 233px;
    margin: auto auto 137px auto;
}
.structure .all-sec{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
}
/* .structure .row,.structure .row .col-md-4{
    padding-left: 0px;
    padding-right: 0px;
    --bs-gutter-x: 0px;
}
.structure p,.structure .row .col-md-4{
    margin-block-start:0px;
    margin-block-end: 0px;
} */
.structure .fill{
    background-color: #C1D34C;
    /* width: 100%; */
    text-align: center;
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
}
.structure .fill p{
    /* width: 100%; */
    padding: 20px 0px;
    color: white;
    font-family: 'Inter', sans-serif;
    font-weight: 600;
}
.structure .table-sec{
    flex: 1;
}
.structure .table-sec.center-border{
    border-left:1px solid #C1D34C;
    border-right:1px solid #C1D34C;
}
.structure .table-sec p{
 font-size: 16px;
 font-weight: 600;
 color: #2C2C2C;
}
.structure .table-sec .first-p{
    padding: 22px 0px;
    border-bottom: 1px solid #C1D34C;
}
.structure .table-sec .second-p{
    padding: 38px 0px;
}

/*objectives section*/
.objectives{
    margin-bottom: 80px;
}
.objectives .objs-title{
color: #134F6C;
font-size: 48px;
font-family: 'Roboto', sans-serif;
margin-bottom: 40px;
}

.objectives .obj-content{
    border: 1px solid #C1D34C;
    border-radius: 10px;
    text-align: center;
    padding: 24px 15px;
    height: 100%;
}
.objectives .obj-content .obj-title{
color: #322C27;
font-family: 'Inter', sans-serif;
font-weight: 600;
font-size: 15px;
text-transform:capitalize;
margin-bottom: 15px;
}
.objectives .obj-content .obj-desc{
    color: #787878;
font-family: 'Inter', sans-serif;
font-weight: 400;
font-size: 16px;
line-height: 50px;
}
/*outcome-goals*/
.outcome-goals{
    margin-bottom: 140px;
}
.outcome-goals ul li{
    list-style-type: circle;
    line-height: 50px;
}
.outcome-goals .head-title{
    color: #134F6C;
font-size: 48px;
font-family: 'Roboto', sans-serif;
margin-bottom: 30px;
}
.outcome-goals .content{
    color: #888888;
    font-size: 20px;
    font-family: 'Inter', sans-serif;
    font-weight: 500;
}
.transparent{
    margin-top: 46px;
    margin-bottom: 11px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
/* scientific */
.scientific{
    margin-bottom: 182px;
    display: flex;
    justify-content: space-between;
}
.scientific .title{
color: #134F6C;
font-size: 36px;
font-family: 'Roboto', sans-serif;
font-weight: 700;
padding-bottom: 19px;
border-bottom:2px solid #C1D34C;
margin-bottom: 32px;
}
.scientific p{
    color: #6C635F;
    font-size: 18px;
    font-family: 'Inter', sans-serif;
    font-weight: 700;
    margin-bottom: 25px;
}
.scientific .img-part{
    /* display: flex; */
    /* height: 100%; */
    width: 40%;
}
.scientific .img-part img{
    width: 100%;
}
.scientific .text-part{
    width: 50%;
}
/*location section*/
.location{
    margin-bottom: 276px;
    display: flex;
    justify-content: space-between;
    /* align-items: center; */
    flex-wrap: wrap;
}
.location .div:first-of-type{
width: 50%;
}
.location .div:last-of-type{
width: 50%;
}
.location .img-part img{
    width: 100%;
}
.location .text-part{
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    height: 100%;
}
.location .text-part h2{
color: #154F6C;
font-size: 36px;
font-weight: 700;
font-family: 'Inter', sans-serif;
}
.location .text-part p{
    text-transform: uppercase;
    font-size: 40px;
    font-family: 'Inter', sans-serif;
    font-weight: 700;
}
.location .img-part{
    position: relative;
    height: 100%;
}
.location .img-part img{
    position: absolute;
    right: -100px;
}

/*contact*/
.contact{
    /* width: 1196px; */
    height: fit-content;
    border: 10px solid white;
    border-radius: 10px;
    margin: auto auto 154px auto;
    display: flex;
}
.contact .background{
    /* height:100%;
    width: 100%; */
    background-image: linear-gradient(#134F6C, #285b74);
    padding: 40px;
    border-radius: 7px;
    position: relative;
    overflow: hidden;
    flex: 1;
    flex-wrap: wrap;
}
.contact .background h2{
    color: white;
    font-size: 28px;
    font-weight: 600;
    font-family: 'Inter', sans-serif;
}
.contact .background .contact-desc{
    color: #C9C9C9;
    font-size: 18px;
    font-weight: 400;
    margin-bottom: 94px;
}
.contact .background ul li{
    margin-bottom: 50px;
    display: flex;
}
.contact .background ul li img{
    margin-right: 25px;
    height: 100%;
}
.contact .background ul li p{
    color: white;
}
.contact .background .sqr,.contact .background .circle{
    position: absolute;
    background-color: #FFF;
    opacity: 15%;
}
.contact .background .sqr{
    width: 184px;
    height: 184px;
    right: -80px;
    bottom: -80px;
    border-radius: 50%;
}
.contact .background .circle{
    width: 94px;
    height: 94px;
    right: 20px;
    bottom: 50px;
    border-radius: 50%;
}
.contact .form-part{
    /* height: 100%; */
    background: white;
    flex: 1.2;
    padding: 50px;
}
.contact .form-part .form {
    display: flex;
    justify-content: space-between;
    margin-bottom: 45px;
}
.contact .form-part .form .input-section{
    display: flex;
    flex-direction: column;
    width: 45%;
}
.contact .form-part .form .input-section label,.contact .form-part .text-area label{
color: #8D8D8D;
font-size: 12px;
font-family: 'Inter', sans-serif;
font-weight: 500;
margin-bottom: 6px;
}
.contact .form-part .form .input-section input,.contact .form-part .text-area input{
border: none;
border-bottom: 1px solid #8D8D8D;
outline: none;
font-size: 12px;
font-weight: 600;
padding-bottom: 10px;
}

.contact .form-part .form .input-section .active{
    border-bottom: 1px solid #011C2A;
}
.contact .form-part .form .input-section .active-label{
    color: #011C2A;
}

.contact .form-part .select-sec{
    display: flex;
    justify-content: space-between;
    margin-bottom: 45px;
    flex-wrap: wrap;
}
.contact .form-part .label-select{
    color: #011C2A;
    font-weight: 600;
    font-size: 14px;
    font-family: 'Inter', sans-serif;
    margin-bottom: 14px;
}
.contact .form-part .select-sec label{
    font-size: 12px;
    font-weight: 400;
    font-family: 'Inter', sans-serif;
    color: #011C2A;
    height: 100%;
}
.contact .form-part .select-sec input{
    font-size: 12px;
    font-weight: 400;
    height: 100%;
    color: #011C2A;
    margin-right: 10px;
}
.contact .form-part .select-sec .selection{
    display: flex;
    align-items: center;
    justify-content: center;
}
.contact .form-part .text-area{
    display: flex;
    flex-direction: column;
    margin-bottom: 45px;
}
.contact .form-part .text-area input::placeholder{
    font-size: 14px;
    font-weight: 500;
    color: #8D8D8D;
}
.btn-section{
    width: 100%;
    display: flex;
    justify-content: flex-end;
}
.btn-section button{
    outline: none;
    border: none;
    background-color: #00838E;
    color: white;
    padding: 15px 48px;
    border-radius: 5px;
    font-size: 16px;
    font-weight: 500;
    font-family: 'Inter', sans-serif;
    opacity: 50%;
}
.btn-section .opacity{
    opacity: 100%;
}

/*footer*/
footer{
    background-color: #134F6C;
    width: 100%;
    height: 100%;
}
footer .information, footer .subscribe{
    font-family: 'Inter', sans-serif;
}
footer .information{
    padding-top: 38px;
    color: #fff;
    font-weight: 400;
}
footer .information .section {
    margin-bottom: 34px;
}
footer .information .section .title{
    font-size: 16.5px;
    margin-bottom: 6px;
}
footer .information .section .value,footer .information .section span{
    font-size: 13.5px;
    margin-bottom: 4px;
}
footer .information .section span{
    text-decoration: underline;
}
footer .subscribe{
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    /* align-items: flex-end; */
    justify-content: center;
}
footer .subscribe .signup{
font-size: 18px;
font-weight: 600;
color: #fff;
margin-bottom: 24px;
}
footer .subscribe .signup-btn{
    display: flex;
    position: relative;
    width: 100%;
    margin-bottom: 16px;
}
footer .subscribe .signup-btn input{
padding: 16px 24px;
outline: none;
border: none;
border-radius: 16px;
/* flex: 3; */
width: 100%;
}
footer .subscribe .signup-btn button{
    position: absolute;
    background-color: #00838E;
    right: 0px;
    color: #fff;
    outline: none;
    border: none;
    border-radius: 16px;
    padding: 16px 35px;
    font-size: 16px;
    font-weight: 600;
    opacity: 50%;
    /* flex: 1; */

}
footer .subscribe .desc{
    opacity: 50%;
    font-size: 13px;
    font-weight: 500;
    color: #fff;
}
footer .line{
    background: #fff;
    width: 100%;
    height: 2px;
    margin-bottom: 23px;
    opacity: 20%;
}
footer .second-part{
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    padding-bottom: 116px;
}
footer .second-part .terms,footer .second-part .social{
    font-family: 'Inter', sans-serif;
    color: #fff;
    font-weight: 400;
    display: flex;
}
footer .second-part .social{
    font-size: 18px;
}
footer .second-part .terms{
    font-size: 13px;
}
footer .second-part .terms p:first-of-type{
    margin-right: 66px;
}
footer .second-part .social ul{
    display: flex;
    margin-left: 27px;
}
footer .second-part .social ul li{
    margin-right: 12px;
    cursor: pointer;
}
/* Register */
.auth-register{
margin-top: 150px;
margin-bottom: 150px;
display: flex;
justify-content: space-between;
align-items: center;
font-family: 'Inter',sans-serif;
}
.auth-register .img-part{
    width: 45%;
    text-align: center;
}
.auth-register .form-part{
    width: 45%;
}
.auth-register .img-part img{
    width: 100%;
}
.auth-register .img-part p{
    color: #888888;
    margin-top: 22px;
    font-size: 16px;
    font-weight: 500;
}
.auth-register .form-part .head{
font-weight: 700;
font-size: 30px;
color: #141414;
}
.auth-register .form-part .details{
    font-size: 16px;
    font-weight: 500;
    color: #8692A6;
    margin-bottom: 19px;
}
.auth-register .form-part .div-input{
    display: flex;
    flex-direction: column;
    margin-bottom: 24px;
}
.auth-register .form-part .div-input label{
font-weight: 600;
color: #8D8D8D;
font-size: 12px;
}
.auth-register .form-part .div-input input{
border-bottom: 1px solid #8D8D8D;
transition: all .1s ease-in;
font-size: 12px;
padding-bottom: 10px;
}
.auth-register .form-part .div-input input:focus{
    border-bottom: 1px solid #C1D34C;
}
.login button{
    width: 100%;
    padding: 23px;
    background-color: #C1D34C;
    color: #fff;
    border-radius: 6px;
    font-weight: 700;
    font-size: 16px;
}
.create-btn{
    width: 100%;
    padding: 23px;
    background-color: none;
    color: #fff;
    border-radius: 6px;
    font-weight: 700;
    font-size: 16px;
}
/*login*/
.login{
    width: 461px;
    margin: 150px auto;
    text-align: center;
}
.login img,.join img{
    width: 100%;
}
.login .login-head{
    color: #141414;
    font-weight: 700;
    font-size: 30px;
}
.login .login-body{
    color: #8692A6;
    font-weight: 500;
    font-size: 16px;
    /* margin-bottom: 40px; */
}
.login .div-input{
    text-align: left;
    margin-top: 32px;
    margin-bottom: 10px;
}
.login .div-input label{
    color: #8D8D8D;
    font-size: 12px;
    font-weight: 700;
}
.login .div-input input{
    display: flex;
    flex-direction: column;
border-bottom: 1px solid #8D8D8D;
font-size: 12px;
padding-bottom: 10px;
padding-top: 10px;
width: 100%;
}
.login button{
    margin-top: 10px;
}
.forgot{
    font-size: 12px;
    font-weight: 700;
}
/*admin dashboard*/
.dashboard .fill{
    background-color: #F5FEFD;
    width: 100%;
    height: 72px;
}
.dashboard .title{
    padding-top: 21px;
    padding-bottom: 21px;
    border-top:1px solid #E0E0E0 ;
    border-bottom:1px solid #E0E0E0 ;
    margin-bottom: 33px;
}
.dashboard .title .phraseBig{
    font-weight: 700;
    font-size: 24px;
    color: #000;
}
.manage-p-btn{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.manage-p-btn button{
    background-color: transparent;
    border-radius: 8px;
    border: 1px solid #E0E0E0;
    font-size: 14px;
    padding: 8px;
    color: #000;
    }
.manage-p-btn button a{
    font-size: 14px;
/* padding: 8px; */
color: #000;
}
.manage-p-btn button img{
margin-right: 8px;
}
.dashboard .group{
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
}
.dashboard .group .back{
    display: flex;
    cursor: pointer;
}
.dashboard .group .back p{
    color: #8692A6;
    font-size: 16px;
    font-weight: 600;
    margin-left: 8px;
}
.dashboard .navigation{
    border-bottom: 1px solid #F5F5F5;
    margin-bottom: 32px;
}
.dashboard .navigation ul{
    display: flex;
}
.dashboard .navigation ul li{
    margin-right: 23px;
    padding-bottom: 10px;
    cursor: pointer;
    color: #AAAAAA;
    font-weight: 500;
    font-size: 16px;
    transition: all .5 ease-in;
}
.dashboard .navigation ul li.active{
    border-bottom: 2px solid #C1D34C;
    color: #134F6C;
}
.dashboard .search{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.dashboard .search .content p:first-of-type{
    font-size: 16px;
    color: #000;
    font-weight: 500;
}
.dashboard .search .content p:last-of-type{
    font-size: 14px;
    color: #6A6A6A;
    font-weight: 400;
}
.search {
    margin-bottom: 32px;
}
.search .filter{
    display: flex;
    align-items: center;
    border: 1px solid #E0E0E0;
    border-radius: 8px;
    padding: 13px;
}
.search .filter img{
    margin-right: 8px;
}
.search .filter input::placeholder{
    font-size: 14px;
    font-weight: 400;
    color: #6A6A6A;
}
/*table*/
.table-users{
    border-radius: 8px;
    width: 100%;
    border: 1px solid #E0E0E0;
    border-width:0px;
    overflow: hidden;
    margin-bottom: 101px;
}
.table-users tbody tr{
    border-width:1px;
}
.table-users thead{
    background-color: #F5F5F5;
    font-size: 14px;
    font-weight: 400;
    color:#000;
}
.table-users thead tr th{
    padding: 16px;
    /* border: none; */
}

/* .table-users tbody tr {
    border-bottom: 1px solid #E0E0E0;
} */

.table-users tbody tr td:not(.table-users tbody .row-btn td){
    font-size: 14px;
    font-weight: 400;
    padding: 24px 16px 40px;
    /* border: none; */
}
.table-users tbody tr td:not(.table-users tbody .row-btn td,.table-users tbody tr td.name,
.table-users tbody tr td.email){
    width: 15%;
}
.table-users tbody tr td.name,.table-users tbody tr td.type,.table-users tbody tr td.view{
    font-weight: 500;

}
.table-users tbody tr td.view, .table-users thead .view{
text-align: right;
}
.table-users tbody tr td.view a{
    border: 1px solid#E0E0E0;
    border-radius: 8px;
    font-size: 14px;
    font-weight: 500;
    padding: 8px 16px;
    color: #000;
}
.table-users tbody tr td.name,
.table-users tbody tr td.email{
    width: 20%;
}
.table-users tbody tr td .status{
    font-size: 10px;
    text-transform: uppercase;
    padding:  8px 0px;
    width: 117px;
    background-color: rgba(212, 209, 247, 20%);
    color: #2E5CB5;
    border-radius: 41px;
    text-align: center;
}
.table-users tbody tr td .status.green{
    background-color: #F6FDF6;
    color: #A3B52E;
}
.table-users tbody tr td .status.red{
    background-color: rgba(247, 209, 209, 20%);
    color: #A84747;
}
.table-users .row-btn {
    background-color: #F5FEFD;
}
.table-users .row-btn td{
    padding: 16px;
    text-align: center;
}

.table-users .row-btn button{
    background: #FFFFFF;
    padding: 8px 16px;
    border-radius: 8px;
    border: 1px solid #E0E0E0;
    font-weight: 600;
}
/* .tfoot {
    background-color:#F5FEFD;
    width: 100%;
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px;
    border: 1px solid #E0E0E0;
    position: absolute;
    left: 0px;
    bottom:-32px;
    display: flex;
    justify-content: center;
    padding: 16px;
} */
.foot-page{
    width: 100%;
    background-color: #C1D34C;
    padding: 26.5px;
    text-align: center;
    color: #fff;
    font-size: 16px;
    font-weight: 400;
}
.foot-page span{
    font-weight: 700;
}
.foot-page .dark{
    color: #1081A3;
    margin-left: 8px;
}
.foot-page .light{
    margin-right: 4px;
}
.pdf-information{
    display: flex;
    /* align-items: center; */
    justify-content: space-between;
    margin-bottom: 32px;
}
.pdf-information .pdf-part{
    width: 30%;
}
.pdf-information .pdf-part .name{
    font-size: 24px;
    font-weight: 700;
}
.pdf-information .pdf-part .user-status{
    font-size: 10px;
    font-weight: 400;
    color: #2E5CB5;
    text-transform: uppercase;
    margin-left: 8px;
}
.pdf-information .pdf-part .user-status.approved{
    color: #A3B52E ;
}
.pdf-information .pdf-part .user-status.reject{
    color: #A84747 ;
}
.pdf-information .pdf-part .sentence{
    font-size: 16px;
    margin-top: 8px;
    margin-bottom: 16px;
}
.pdf-information .pdf-part .sentence span{
    font-weight: 700;
}
.pdf-information .pdf-part .change-st{
    font-weight: 500;
    font-size: 16px;
    color: #aaa;
    margin-bottom: 16px;
}
.pdf-information .pdf-part .status-values{
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 10px;
    font-weight: 500;
    margin-bottom: 32px;
}
.pdf-information .pdf-part .status-values p{
    border-radius: 41px;
    text-transform: uppercase;
padding: 8px 22px;
    text-align: center;
    cursor: pointer;
}
.pdf-information .pdf-part .status-values p:first-of-type{
    background-color: #2E5CB5;
    color: #D4E3FF;
}
.pdf-information .pdf-part .status-values p:nth-of-type(2){
    background-color: #DAFDDA;
    color: #A3B52E ;
}
.pdf-information .pdf-part .status-values p:last-of-type{
    background-color: #F7D4D4;
    color: #A84747;
}
.pdf-information .pdf-part .preview-part{
background-color: #F5F5F5;
border-radius: 8px;
border: 1px solid #E0E0E0;
padding: 45px 56px;
}
.pdf-information .pdf-part .preview-part .cv-part{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.pdf-information .pdf-part .preview-part .cv-part img{
    width: 156px;
    height: 218px;
}
.pdf-information .pdf-part .preview-part .cv-part p{
   font-size: 12px;
   font-weight: 400;
   margin-bottom: 12px;
   margin-top: 12px;
   color: #A0A0A0;
}
.cv-btns{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.pdf-information .pdf-part .preview-part .cv-part .cv-btns button,.cv-btns a{
  border: 1px solid #E0E0E0;
  border-radius: 8px;
  background-color: transparent;
  font-size: 14px;
  padding-top: 8px;
  padding-bottom: 8px;
  width: 116px;
  display: block;
  color: #000;
  text-align: center;
}
.pdf-information .pdf-part .preview-part .cv-part .cv-btns a{
    margin-right: 12px;
}
.react-pdf__Page__canvas {
    margin: 0 auto;
    width: 156px !important;
    height: 218px !important;
}
.react-pdf__Page__textContent.textLayer,.react-pdf__Page__annotations.annotationLayer{
    display: none !important;
}
.abstract{
    margin-bottom:67px ;
}
.abstract p:first-of-type{
    font-size: 16px;
    font-weight: 700;
    width: 100%;
    padding-bottom: 4px;
    border-bottom: 1px solid #E0E0E0;
    margin-bottom: 4px;
}
.abstract p:last-of-type{
    font-size: 14px;
    font-weight: 400;
    width: 100%;
    color: #6A6A6A;
}
.info-part{
    border-radius: 8px;
    border: 1px solid #E0E0E0;
    padding: 24px;
    width: 65%;
}
.profile-info{
    width: 50%;
    margin-top: 24px;
    min-height: 0px;
    max-height: 0px;
    opacity: 0;
    transition: all 0.5s ease-in-out;
    -moz-transition: all 0.5s ease-in-out;
    -webkit-transition: all 0.5s ease-in-out;
    -o-transition: all 0.5s ease-in-out;
    -ms-transition: all 0.5s ease-in-out;
}
.profile-info.show{
    opacity: 1;
    /* min-height: 477px; */
    min-height: 350px;
}
.margin-apps{
    margin-top: 30px;
        min-height: 0px;
    max-height: 0px;
    opacity: 0;
    transition: all 0.5s linear;
    -moz-transition: all 0.5s linear;
    -webkit-transition: all 0.5s linear;
    -o-transition: all 0.5s linear;
    -ms-transition: all 0.5s linear;
}
.margin-apps.show{
    opacity: 1;
    min-height: 200px;
}
.edit-mode{
    width: 50%;
    margin-top: 24px;
}
.edit-mode .info{
    display: flex;
    flex-direction: column;
}
.info-part .info:not(:last-of-type){
    margin-bottom: 24px;
}
.info-part .info .head,.profile-info .info .head,.edit-mode .info .head {
    font-size: 16px;
    font-weight: 700;
    color: #000;
}
.info-part .info .value,.profile-info .info .value,.edit-mode .info .value {
    font-size: 14px;
    font-weight: 400;
    color: #6A6A6A;
}
.edit-mode .info .value {
border-bottom: 1px solid #AEAEAE;
padding-bottom: 9px;
margin-bottom: 12px;
}
.cv-action-btns button{
background-color: transparent;
border-radius: 8px;
border: 1px solid #E0E0E0;
font-size: 14px;
padding: 8px;
color: #000;
}

.cv-action-btns button:first-of-type{
    padding: 8px 18px;
    /* margin-right: 16px; */
}
.choose-page{
    background-color: #F5F9FF;
    width: 100%;
    min-height:100vh;
}
.user-icon,.user-icon-sm{
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background-color: #E2FEE6;
    display: flex;
    align-items: center;
    justify-content: center;
}
.user-icon p,.user-icon-sm p{
    text-transform: uppercase;
}
.join{
    width: 461px;
    margin: auto;
    text-align: center;
    padding-bottom: 80px;
}
.join-head{
    color: #134F6C;
    font-size: 24px;
    font-weight: 700;
}
.join-body{
    color: #888888;
    font-size: 16px;
    font-weight: 400;
    margin-top: 16px;
    margin-bottom: 16px;
}
.choose-part,.not-choose-part{
    display: flex;
    justify-content: space-between;
    padding: 28px;
    border-radius: 6px;
    margin-bottom: 24px;
    cursor: pointer;
    border: 1px solid transparent;
    transition: all .4s ease-in;
}
.choose-part{
    background-color: #fff;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, .25);
}
.not-choose-part{
    background-color: #b3b3b3;
}
.choose-part.active ,.choose-part:hover{
    background-color: transparent;
    border: 1px solid #ABBE3A;
    box-shadow: none;
}
.choose-part .paraghs,.not-choose-part .paraghs {
    text-align: left;
}
.choose-part .paraghs p:first-of-type,.not-choose-part .paraghs p:first-of-type{
    font-size: 16px;
    font-weight: 600;
}
.choose-part .paraghs p:last-of-type,.not-choose-part .paraghs p:last-of-type{
    font-size: 14px;
    font-weight: 400;
    color: #8692A6;
}
.choose-part .icon,.choose-part .shape,.not-shape,.not-choose-part .icon{
    display: flex;
    align-items: center;
    justify-content: center;
}
.choose-part .icon i,.not-choose-part .icon i{
color: transparent;
transition: all .4s ease-in;
}
.choose-part:hover .icon i,.choose-part.active .icon i{
color: #ABBE3A;
}
.choose-part .shape,.not-shape{
    position: relative;
    width: 56px;
    height: 56px;
    transition: all .5s ease-in;
    /* border-radius: 5px; */
    -webkit-clip-path: polygon(50% 0%, 100% 38%, 82% 100%, 18% 100%, 0% 38%);
}
.choose-part .shape{
    background-color: #ABBE3A;
}
.choose-part .shape-inside,.not-shape-inside{
    position: absolute;
    width: 52px;
    height: 52px;
    transition: all .5s ease-in;
    /* border-radius: 5px; */
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center; 
    -webkit-clip-path: polygon(50% 0%, 100% 38%, 82% 100%, 18% 100%, 0% 38%);
}
.choose-part .shape i{
   color: #ABBE3A; 
}
.choose-part .not-shape i{
   color: #888; 
}
.choose-part:hover .shape-inside,.choose-part.active .shape-inside{
    background-color: #ABBE3A;
}
.choose-part:hover .shape i,.choose-part.active .shape i{
    color: #fff;
}
.join a,.next,.join button{
    display: block;
    width: 100%; 
    padding: 10px;
    background-color: #C1D34C;
    color: #fff !important;
    border-radius: 6px;
    font-weight: 500;
    font-size: 14px;
}
.next:disabled {
    background-color: #A9A9A9; /* Light gray background for disabled state */
    cursor: not-allowed; /* Shows a 'not-allowed' cursor on hover */
}

.speaker-back{
    margin-top: 32px;
}
.speaker-form {
    width: 60%;
    margin: auto;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1), 0 6px 20px rgba(0, 0, 0, 0.1); /* Adds a subtle shadow */
    border-radius: 8px; /* Optional: Adds rounded corners for a smoother look */
    background: #fff; /* Ensures the form has a solid white background */
    padding: 20px; /* Adds some padding inside the form for content spacing */
}

.speaker-body{
   margin-top: 3px;
   color: #8692A6;
   font-size: 14px;
}
.fields{
    margin-top: 24px;
    display: flex;
    flex-direction: column;
}
.fields label{
    color: #8D8D8D;
    font-size: 12px;
    margin-bottom: 6px;
}
.fields input[type=text],.fields input[type=email],.fields input[type=number]{
    padding-bottom: 8px;
    border-bottom: 1px solid #8D8D8D;
    font-size: 12px;
}
.fields .author{
    color: #8D8D8D;
    font-size: 14px;
    font-weight: 700;
    margin-bottom: 12px;
}
.fields .field-desc{
    font-size: 12px;
    color: #8D8D8D;
    text-align: justify;
}
.titles{
    display: flex;
    align-items: center;
    margin-top: 14px;
}
.titles .horizontal {
    margin-right: 51px;
}
.titles .horizontal input,.titles-vertical .vertical input {
    width: 18px;
    height: 18px;
    margin-right: 10px;
    accent-color: #000;
}
.titles label,.titles input{
    color: #000;
    height: 100%;
    margin-bottom: 0px;
}
.titles .horizontal{
    display: flex;
    align-items: flex-end;
    height: 100%;
}
.titles-vertical{
    display: flex;
    flex-direction: column;
    margin-top: 12px;
}
.titles-vertical .vertical{
    display: flex;
    align-items: center;
    margin-bottom: 7px;
}
.titles-vertical .vertical label{
    margin-left: 2px;
    font-size: 16px;
    color: #011C2A;
    margin-bottom: 0px;
}
.speaker-form button{
    margin-top: 24px;
}
.speaker-form .save{
    width: 100%;
    background-color: transparent;
    color: #010B38;
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 34px;
}
.fields .file{
    background: #F5F9FF;
    width: 100%;
    height: 56px;
    border-radius: 6px;
    /* border: 1px solid #8D8D8D; */
    margin-top: 8px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 14px;
}
.browes-files{
position: absolute;
display: flex;
align-items: center;
}
.browes-p{
    color: #134F6C;
    font-size: 16px;
    font-weight: 500;
    margin-left: 8px;
}
.star{
    color: #C83F3F;
}
.uploaded-file{
    margin-bottom: 17px;
    transition: all .5s linear;
}
.uploaded-file,.uploaded-file .desc{
    display: flex;
    align-items: center;
}
.uploaded-file .desc {
    background: #F5F5F5;
    flex: 2;
    padding: 8px 16px;
    border-radius: 8px;
}
.uploaded-file .desc p{
    margin-left: 5px;
    color: #5B5B5B;
    font-size: 16px;
}
.uploaded-file .desc p span{
    font-weight: 600;
}
.uploaded-file .trash{
    /* flex: 1; */
    margin-left: 12px;
    cursor: pointer;
}
/* .file  img{
    position: absolute;
} */
.file input[type=file]{
width: 100%;
height: 100%;
opacity: 0;
cursor: pointer;
}
.abst{
    background: #F5F9FF;
    width: 100%;
    height: 95px;
    border-radius: 6px;
    border: 1px solid #DEDEDE;
    margin-top: 8px;
    position: relative;
    overflow: hidden;
}
.author-area{
    background: #F5F9FF;
    width: 100%;
    height: 95px;
    border-radius: 6px;
    border: 1px solid #DEDEDE;
    margin-top: 12px;
    margin-bottom: 12px;
    outline: none;
    resize: none;
    padding: 10px;
    font-size: 12px;
}
.abst textarea{
    outline: none;
    width: 100%;
    border: none;
    padding: 10px;
    background-color: transparent;
    resize: none;
}
.abst span {
    position: absolute;
    right: 8px;
    bottom: 6px;
    font-size: 12px;
    color: #707070;
}
.checkboxes{
    margin-bottom: 9px;
}
.checkboxes .check {
    margin-top: 10px;
    display: flex;
    align-items: flex-start;
}
.checkboxes .check input{
    width: 18px;
    height: 18px;
    accent-color: #011C2A;
}
.checkboxes .check label{
   margin-bottom: 0px;
   color: #011C2A;
   font-size: 16px;
   margin-left: 12px;
}
.progress-bar{
    background: rgba(179, 179, 200, .67);
    border: 1px solid #888888;
    width: 100%;
    height: 8px;
    border-radius: 2px;
}
.center{
    text-align: center;
}
.progress-value{
    color: #0A100D;
    font-size: 16px;
    margin-top: 11px;
}
.workshop{
    display: flex;
    align-items: center;
}
.workshop input{
height: 100%;
accent-color: #000;
margin-right: 7px;
}
.error-msg{
    display: block;
    width: 100%;
    color: red;
    font-size: 10px;
    font-weight: 700;
    padding-top: 4px;
    padding-bottom: 4px;
    transition: display .6s ease-in;
}
.message-msg{
    display: block;
    width: 100%;
    color: green;
    font-size: 10px;
    font-weight: 700;
    padding-top: 4px;
    padding-bottom: 4px;
    transition: display .6s ease-in;
}
.specific-login{
    background-color: none;
    color: #C1D34C;
    font-size: 14px;
    font-weight: 500;
    margin-right: 40px;
}
.login-btn{
    background-color: none;
    color: #C1D34C;
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 10px;
}
.modal{
    position:fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #FFF;
    padding: 50px;
    z-index: 1000;
    border-radius: 8px;
    width: 300px;
}
.save-btn{
    color:#fff;
    background:#C1D34C;
    width:100%;
    margin-bottom:16px;
    font-size:18px;
    font-weight:500;
    border-radius:8px;
    padding:8px
}
.cancel-btn{
    width:100%;
    margin-bottom:16px;
    font-size:18px;
    font-weight:500;
    background:none;
    border-radius:8px;
    padding:8px;
    border:1px solid #B4B4B4;
}
.dont-btn{
    width:100%;
    margin-bottom:16px;
    font-size:18px;
    font-weight:50;
    background:none;
    border-radius:8px
}
/* profile  page*/
.back-title{
    background: #FCFCFC;
    padding-top: 13px;
    padding-bottom: 8px;
    height: 72px;
    margin-bottom: 3px;
}
.title-edit{
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    position: relative;
}
.title-arrow{
    display: flex;
    align-items: center;
    position: absolute;
    left: -40px;
    top: 0px;
}
.title-arrow h2{
color: #1081A3;
font-size: 20px;
font-weight: 500;
}
.title-arrow p{
    color: #6A6A6A;
    font-size: 14px;
}
.title-arrow .text{
    margin-left: 13px;
}
.title-arrow .arrow{
    width: 20px;
    height: 20px;
    border: 1px solid #C1D34C;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}
.title-arrow .arrow i{
    color:  #C1D34C;

}
.title-edit .edit-btn{
    position: absolute;
    right: 0px;
    top: 10px;
}
.title-edit .edit-btn button{
    border-radius: 8px;
    padding: 8px 14px;
    font-size: 13px;
}
.title-edit .edit-btn .edit{
    background-color: #F5F5F5;
    border: 1px solid #EFEFEF;
    color: #818181;
}
.title-edit .edit-btn button i{
/* font-size: 13px; */
margin-right: 8px;
}
.edit-save{
    background-color: #C1D34C;
    color: #fff;
    margin-right: 14px;
}
.edit-cancel{
    background-color: transparent;
    border: 1px solid #E0E0E0;
    color: #000000;
}
/* .table-users tfoot tr td {
    width: 100%;
} */
/*Media Query*/
@media (max-width:1200px) {
    .pdf-information{
        flex-direction: column;
    }
    .pdf-information .pdf-part, .pdf-information .info-part{
        width: 100%;
    }
    .pdf-information .pdf-part{
        margin-bottom: 12px;
    }
}
@media (max-width:1199px) {
    .home-header .big-nav,.home-header .register,.user-icon{
        display: none;
    }
    .menu{
        display: block;
    }
    .banner .p3-date {
        font-size: 50px;
    }
    .banner .last-p {
        margin-bottom: 14px;
    }
    .banner .img img{
        width: 100%;
    }
    .soon{
        flex-direction: column;
    }
    .soon .div{
        width: 100%;
    }
    .soon .back-sqr{
        width: 100%;
        margin-bottom: 34px;
    }
    .soon .front-sqr {
        justify-content: center;
    }
    .coming-img img,.themes .theme-img img, .scientific .img-part img,.location .img-part img {
        width: 100%;
    }
    .themes .theme-img,.scientific .img-part {
        justify-content: center;
        align-items: center;
    }
    .structure{
        width: 100%;
        height: fit-content;
    }
    .structure .all-sec{
        flex-direction: column;
    }
    .structure .all-sec .table-sec{
        width: 100%;
        height: 100%;
    }
    .structure .all-sec .table-sec.center-border{
        border-left: none;
        border-right: none;
    }
    .structure .all-sec .table-sec .first-p{
        border-top: 1px solid #C1D34C;;
    }
    .objectives .marg{
        margin-bottom: 14px;
    }
    .outcome-goals .transparent img{
        width: 100%;
    }
    .scientific {
        margin-bottom: 100px;
    }
    .location{
        flex-direction: column;
        /* margin-bottom: 500px; */
    }
    .location .div:first-of-type, .location .div:last-of-type{
        width: 100%;
    }
    .location .img-part {
        margin-top: 15px;
    }
    .location .img-part img {
        right: 0px;
    }
    .contact{
        flex-direction: column;
        height: fit-content;
        width: 100%;
        /* margin-top: 500px; */
    }
}
@media (max-width:992px) {
    .table-users thead{
        display: none;
    }
    .table-users tbody tr{
        display: flex;
        flex-direction: column;
        text-align: center;
    }
    .table-users tbody tr td:not(:last-of-type){
        border-bottom: 1px solid #eae8e8;
    }
    .table-users tbody tr td:not(.table-users tbody .row-btn td,.table-users tbody tr td.name,
    .table-users tbody tr td.email),
    .table-users tbody tr td.name,.table-users tbody tr td.email,
    .table-users tbody tr td.type,
    .table-users tbody tr td .status{
        width: 100%;
    }
    .table-users tbody tr td.view{
        text-align: unset;
    }
    .welcome,.confrence,.benefit{
        flex-direction: column;
    }
    .welcome div {
        width: 100%;
        text-align: center;
    }
    .welcome div,.confrence .img,.confrence .parag,.benefit .img,.benefit .parag {
        width: 100%;
    }
    .confrence .parag,.benefit .parag{
        margin-top: 20px;
    }
    .confrences-imgs .sections-conf {
        flex-direction: column;
    }
    .confrence-sec{
        margin-bottom: 75px;
    }
    .sections-conf .confrence-sec{
        width: 100%;
    }
}
@media (max-width:767px) {
    .auth-register,.scientific{
        flex-direction: column;
    }
    .auth-register .img-part,.auth-register .form-part,.scientific .img-part,.scientific .text-part{
        width: 100%;
    }
    .auth-register .img-part{
        margin-bottom: 50px;
    }
    .dashboard .navigation ul{
        flex-direction: column;
    }

}
@media (min-width:767px) and ( max-width:991px) {
    .scientific .title {
        font-size: 22px;
    }
}
@media (min-width:767px) and ( max-width:1199px) {
    .location{
        margin-bottom: 500px;
    }
}
@media (max-width:733px) {
    .dashboard .search {
        flex-direction: column;
    }
    .dashboard .search .filter,.dashboard .content {
        width: 100%;
    }
    .dashboard .content{
        margin-bottom: 16px;
    }
}
@media (max-width:600px) {
    .title-arrow{
        left: 0px;
    }
    .back-title{
        height: fit-content;
    }
    .title-arrow,.title-edit,.title-edit .edit-btn {
        position: unset;
    }
    
    .title-edit{
        flex-direction: column;
        align-items: normal;
    }
    .title-edit .edit-btn{
        margin-top: 12px;
        text-align: center;
    }
    .edit-mode,.profile-info{
        width: 100%;
    }
    .manage-p-btn{
        flex-direction: column;
    }
    .manage-p-btn button{
        margin-top: 10px;
    }
}
@media ( max-width:500px) {
    .contact .form-part .form {
        flex-direction: column;
    }
    .contact .form-part .form .input-section{
        width: 100%;
        margin-bottom: 10px;
    }
}
@media (max-width:491px) {
    .login,.join{
        width: 100%;
    }
    .speaker-form{
        width: 97%;
        padding-left: 4px;
    }
    .choose-part, .not-choose-part{
        flex-direction: column;
        align-items: center;
    }
    .choose-part .paraghs,.not-choose-part .paraghs {
        text-align: center;
    }
    .check{
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
    }
    .checkboxes .check input,.checkboxes .check label {
        width: 100%;
    }
}
@media ( max-width:452px) {
    .banner .p3-date {
        font-size: 30px;
    }
    .confrence-sec .img img{
        width: 100%;
    }
}
@media (max-width:400px) {
    .dashboard .group,.pdf-information .pdf-part .status-values {
        flex-direction: column;
    }
    .pdf-information .pdf-part .name {
        font-size: 14px;
    }
    .pdf-information .pdf-part .status-values p {
        width: 100%;
        margin-bottom: 10px;
    }
    .dashboard .group .back {
        margin-bottom: 10px;
    }
    .cv-action-btns{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
    .cv-action-btns button:first-of-type {
        margin-right: 0px;
        margin-bottom: 10px;
    }
    .cv-action-btns{
        width: 100%;
    }
    .mobile-menu{
        width: 100%;
    }
    .title-arrow{
        flex-direction: column;
        align-items: normal;
    }
    .title-arrow .text{
        margin-left: 0px;
        margin-top: 9px;
    }
}
@media (max-width:356px) {
    .auth-register{
        margin-top: 0px;
    }
    .auth-register .form-part .head{
        font-size: 20px;
    }
    .navigation ul{
        flex-direction: column;
        text-align: center;
    }
    .titles{
        flex-direction: column;
        align-items: flex-start;
    }
    .titles div{
        margin-bottom: 6px;
    }
}
/* @media ( max-width:541px) {
    .contact{
        margin-top: 0px;
    }
} */