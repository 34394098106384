/* Basic Reset */
* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

/* Google Fonts */
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700&display=swap');

/* Container styles */
.workshops {
    font-family: 'Open Sans', sans-serif; /* More professional font */
    max-width: 1000px;
    margin: 0 auto;
    padding: 20px;
}

/* Session header styles */
.session-header {
    font-size: 24px;
    color: #333;
    text-align: center;
    margin: 40px 0;
    font-weight: 600; /* Bolder font for headers */
    animation: fadeIn 1s ease-in-out;
}

/* Card styles */
.workshop-card {
    background-color: #134e6c28; /* Use white for a cleaner look */
    border-left: 4px solid #5c6bc0; /* Colorful side border for visual appeal */
    box-shadow: 0 4px 8px rgba(0,0,0,0.1); /* Subtle shadow for depth */
    margin-bottom: 20px;
    padding: 20px;
    border-radius: 8px; /* Rounded corners for a modern look */
    transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}


.workshop-card-lock {
  background-color: #2e2929; /* Use white for a cleaner look */
  border-left: 4px solid #5c6bc0; /* Colorful side border for visual appeal */
  box-shadow: 0 4px 8px rgba(0,0,0,0.1); /* Subtle shadow for depth */
  margin-bottom: 20px;
  padding: 20px;
  border-radius: 8px; /* Rounded corners for a modern look */
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}

.workshop-card:hover {
    transform: translateY(-3px);
    box-shadow: 0 12px 24px rgba(0,0,0,0.2); /* More prominent shadow on hover */
}

.workshop-time {
    background-color: #5c6bc0; /* Consistent color with the side border */
    color: white;
    padding: 8px 16px;
    border-radius: 16px; /* More rounded for a modern pill shape */
    display: inline-block;
    margin-bottom: 15px;
    font-weight: 700; /* Make the time bold */
    animation: popIn 0.5s ease-in-out;
}

.workshop-info {
    animation: slideIn 0.5s ease-in-out;
}

.workshop-title {
    font-weight: 700; /* Bold for importance */
    margin-bottom: 8px;
    text-align: center;
    font-size: 18px; /* Slightly larger for emphasis */
}

.workshop-speaker {
    font-style: normal; /* Remove italics for a cleaner look */
    color: #555;
    font-size: 16px; /* Ensure readability */
    margin-left: 10px; /* Spacing for visual separation */
}


  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  
  @keyframes popIn {
    0% {
      transform: scale(0);
    }
    80% {
      transform: scale(1.1);
    }
    100% {
      transform: scale(1);
    }
  }
  
  @keyframes slideIn {
    from {
      transform: translateX(-100%);
      opacity: 0;
    }
    to {
      transform: translateX(0);
      opacity: 1;
    }
  }
  
  /* Responsive */
  @media (max-width: 768px) {
    .workshop-session {
      display: flex;
      flex-direction: column;
    }
  
    .workshop-card {
      margin-left: 0;
      margin-right: 0;
    }
  }
  
  @media (max-width: 480px) {
    .session-header {
      font-size: 20px;
    }
  
    .workshop-time {
      font-size: 14px;
    }
  }
  