/* PROJECT STYLING */
.profile-dropdown {
    display: inline-block;
    position: relative;
    margin: -40px 50px 50px 50px;
    font-weight: bold;
    font-size: 1.3rem;
    border-radius: 3px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  .profile-dropdown * {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  .profile-dropdown input[type="checkbox"] {
    display: none;
  }
  .profile-dropdown input[type="checkbox"]:checked ~ ul {
    display: block;
    animation: pulse 0.5s;
  }
  .profile-dropdown input[type="checkbox"]:checked ~ img {
    background: #c1d34c;
  }
  .profile-dropdown input[type="checkbox"]:checked ~ label {
    background: #c1d34c;
  }
  .profile-dropdown input[type="checkbox"]:checked ~ label i {
    color: #f2f2f2;
  }
 
  .profile-dropdown img {
    display: inline-block;
    background: #d9d9d9;
    height: 2.5rem;
    vertical-align: middle;
    margin-right: 1rem;
    margin: 0.5rem 0.75rem 0.5rem 0.5rem;
    border-radius: 50%;
  }
  .profile-dropdown span {
    display: inline-block;
    vertical-align: sub;
    width: 160 px;
    margin-right: 0.5rem;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .profile-dropdown ul {
    display: none;
    list-style: none;
    padding: 0;
    margin: 0;
    background: #fff;
    position: absolute;
    top: 100%;
    right: 0;
    width: 100%;
    border-radius: 3px;
  }
  .profile-dropdown ul li a {
    display: block;
    padding: 0.75rem 1rem;
    text-decoration: none;
    color: #b3b3b3;
    font-size: 1rem;
  }
  .profile-dropdown ul li a i {
    font-size: 1.3rem;
    vertical-align: middle;
    margin: 0 0.75rem 0 -0.25rem;
  }
  .profile-dropdown ul li a:hover {
    background: #e6e6e6;
  }
  .profile-dropdown ul li:first-child a:hover {
    border-radius: 3px 3px 0 0;
  }
  .profile-dropdown ul li:last-child a:hover {
    border-radius: 0 0 3px 3px;
  }
  .profile-dropdown > label {
    position: relative;
    height: 3.5rem;
    display: block;
    text-decoration: none;
    background: transparent;
    color: #333;
    box-sizing: border-box;
    padding: 0.9rem;
    float: right;
    border-radius: 0 3px 3px 0;
  }
  .profile-dropdown > label i {
    color: #cccccc;
    font-size: 1.75rem;
  }
  .profile-dropdown:after {
    content: "";
    display: table;
    clear: both;
  }
  
  /* TEMPLATE STYLING */
  body {
    /* background: #2c3e50; */
    margin: 0;
    padding: 0;
    font-family: Lato;
  }
  h1 {
    text-align: center;
    font-size: 3rem;
    color: rgba(0, 0, 0, 0.5);
    text-transform: uppercase;
    margin: 2rem 0 0;
    letter-spacing: 0.5rem;
  }
  .container {
    width: 80%;
    /* margin: 4rem auto 2rem; */
  }
  .container .half {
    width: 50%;
    float: left;
    margin-bottom: 2rem;
  }
  .container:after {
    content: "";
    display: table;
    clear: both;
  }
  p.subtitle {
    color: rgba(0, 0, 0, 0.5);
    font-weight: bold;
    text-align: center;
    margin: 0.5rem 0 2rem;
    letter-spacing: 0.1rem;
  }
  .background {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    opacity: 0.25;
    z-index: -1;
  }
  

  /* Your existing CSS */
.profile-dropdown input[type="checkbox"] {
    display: none;
    
  }

  
  .profile-dropdown input[type="checkbox"]:checked ~ ul {
    display: block;
    animation: pulse 0.5s;
  }
  .profile-dropdown input[type="checkbox"]:checked ~ .dropdown-arrow:after {
    content: "\25b2"; /* Unicode for up arrow */
    margin-top: -50px;
  }
  .profile-dropdown input[type="checkbox"]:not(:checked) ~ .dropdown-arrow:after {
    content: "\25bc"; /* Unicode for down arrow */
    margin-top: -50px;
  }
  .profile-dropdown .dropdown-arrow:after {
    content: "\25bc"; /* Default to down arrow */
    display: inline-block;
    margin-left: 5px;
    margin-top: -50px;
  }
  