.conference-container {
  padding: 20px;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  border-radius: 8px; /* Add border radius for card-like appearance */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  max-width: 100%; /* Ensure it doesn't overflow */
  overflow: auto; /* Enable horizontal scrolling if needed */
}

  .conference-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  .conference-table th,
  .conference-table td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
  }
  
  .conference-table th {
    background-color: #004a99;
    color: #ffffff;
    text-align: center;
  }
  
  .conference-table td {
    text-align: center;
  }
  
  .conference-table tr:nth-child(even) {
    width: 100%;
  }
  
  .conference-table tr:hover {
    background-color: #e8f4ff;
  }
  
  .conference-table h2, .conference-table h3 {
    text-align: center;
    color: #004a99;
  }
  
  /* Responsive Table */
  @media screen and (max-width: 600px) {
    .conference-table thead {
    }
  
    .conference-table, .conference-table tbody, .conference-table tr, .conference-table td {
      display: contents;
      width: 100%;
    }
  
    .conference-table tr {
      margin-bottom: 15px;
      width: 100%;
    }
  
    .conference-table td {
      text-align: right;
      padding-left: 50%;
      text-align: right;
      position: relative;
    }
  
    .conference-table td::before {
      content: attr(data-label);
      position: absolute;
      left: 0;
      width: 50%;
      padding-left: 15px;
      font-weight: bold;
      text-align: left;
    }
  }
  /* Responsive Table */
@media screen and (max-width: 600px) {
  .conference-table {
    overflow-x: auto;
    white-space: nowrap;
    border-collapse: collapse;
    width: 100%;
  }

  .conference-table thead {
    display: none;
  }

  .conference-table tbody,
  .conference-table tr,
  .conference-table th,
  .conference-table td {
    display: block;
    width: 100%;
    text-align: center;
    border: none;
  }

  .conference-table tr {
    width: 100%;

background-color: #ddd;    margin-bottom: 10px;
  }

  .conference-table td {
    border: none;
    padding: 8px;
    text-align: center;
   
  }

  .conference-table td::before {
    content: attr(data-label);
    position: absolute;
    left: 5px;
    top: 0;
    width: 50%;
    padding-right: 5px;
    font-weight: bold;
    text-align: left;
  }

  .conference-table tr:nth-child(even) {
  }

  .conference-table tr:hover {
    background-color: #e8f4ff;
  }

  .conference-table h2,
  .conference-table h3 {
    text-align: center;
    color: #004a99;
  }
}
.conference-cards {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.conference-card {
  width: 300px;
  margin: 10px;
}
/* Add styles for conference cards */
.conference-cards {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.conference-card {
  width: 300px;
  margin: 10px;
  border: 1px solid #ddd;
  border-radius: 8px;
  overflow: hidden;
  transition: transform 0.3s ease-in-out;
}

.conference-card:hover {
  transform: scale(1.05);
}

.conference-card .card-body {
  padding: 20px;
  width: 100%;
}

.conference-card .card-title {
  font-size: 1.2em;
  font-weight: bold;
  color: #333;
}

.conference-card .card-text {
  font-size: 1em;
  color: #666;
}


.conference-content {
  overflow-x: auto; /* Handles horizontal scrolling if table is too wide */
}

.conference-card-mobile {
  display: none;
}

.conference-table-desktop {
  width: 100%;
  overflow-x: auto; /* Handles horizontal scrolling if table is too wide */
}

/* Hide the table and show cards on smaller screens */
@media screen and (max-width: 600px) {
  .conference-table-desktop {
    display: none;
  }

  .conference-card-mobile {
    display: block;
  }
}

.conference-cards {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0;
  margin: 0;
  list-style: none;
}

.conference-card {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin: 10px;
  border-radius: 8px;
  overflow: hidden; /* Ensures no content spills outside the card */
  border: 1px solid #ddd;
}

.conference-card .card-body {
  padding: 15px;
}

@media screen and (max-width: 600px) {
  .conference-card {
    width: 90%; /* Makes card width responsive */
    margin-bottom: 20px;
  }

  .conference-card .card-body {
    padding: 20px;
  }

  .conference-card .card-title {
    font-size: 1em; /* Adjust font size for mobile */
  }

  .conference-card .card-text {
    font-size: 0.9em; /* Adjust font size for mobile */
  }
}
