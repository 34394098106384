.workshops-tabs {
    margin-bottom: 20px;
    margin-top: 150px;
    display: flex;
    justify-content: center;
  }
  
  .workshops-tabs button {
    padding: 10px 20px;
    margin: 0 5px;
    border: none;
    background-color: #f0f0f0;
    cursor: pointer;
  }
  
  .workshops-tabs button.active-tab {
    background-color: #a9afb663;
    color: white;
  }
  
  .workshop {
    margin: 10px 0;
  }
  
  /* Further styling as needed */
 

.workshop-card:hover {
    transform: translateY(-3px);
    box-shadow: 0 12px 24px rgba(0,0,0,0.2); /* More prominent shadow on hover */
}
.table-users {
  border-collapse: collapse;
  width: 100%;
}

.table-header, .table-data {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}

.table-row:nth-child(even) {
  background-color: #f2f2f2;
}

.table-row:hover {
  background-color: #ddd;
}


.table-workshops {
  border-collapse: collapse;
  width: 100%;
}

.table-header, .table-data {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}

.table-row:nth-child(even) {
  background-color: #f2f2f2;
}

.table-row:hover {
  background-color: #ddd;
}
