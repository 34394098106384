.sponsor-card {
    background-color: #f8f9fa; /* Background color */
    border-radius: 10px; /* Rounded corners */
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1); /* Box shadow */
    overflow: hidden; /* Hide overflowing content */
    transition: transform 0.3s ease-in-out; /* Smooth transition */
}

.sponsor-card:hover {
    transform: translateY(-5px); /* Move the card up on hover */
}

.sponsor-image {
    width: 100%; /* Full width image */
    transition: transform 0.3s ease-in-out; /* Smooth transition */
}

.sponsor-card:hover .sponsor-image {
    transform: scale(1.1); /* Zoom in the image on hover */
}
